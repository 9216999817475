<template>
  <el-card class="box-card full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item v-if="loginInfo.orgType==1" :label="$t('affiliationOrg')">
          <el-select v-model="queryForm.orgInfoCode" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option v-for="(t,i) in orgList" :key="i" :label="t.name+' -- '+(t.orgInfoCode)" :value="t.orgInfoCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('customerName')">
          <el-input v-model="queryForm.name" size="small" :placeholder="$t('customerName')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('linkPhone')">
          <el-input v-model="queryForm.phoneNo" size="small" :placeholder="$t('linkPhone')"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('accountType')">
          <el-select v-model="queryForm.type" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('individualAccount')" value="5"></el-option>
            <el-option :label="$t('companyAccount')" value="6"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- 区域选择器 -->
        <el-form-item :label="$t('region')">
          <el-select v-model="queryForm.region" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-for="(item,index) in countryList">
              <el-option-group v-if="item.parentId==0" :key="index" :label="item.name">
                <template v-for="(it,i) in countryList">
                  <el-option v-if="it.parentId==item.id" :key="i+index.toString()" :label="it.name" :value="it.id"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <!-- 城市选择器 -->
        <el-form-item :label="$t('city2')">
          <el-select v-model="queryForm.city" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-if="queryForm.region">
              <template v-for="(item,index) in countryList">
                <template v-if="item.parentId==0">
                  <template v-for="(it,i) in countryList">
                    <el-option-group v-if="it.id==queryForm.region&&it.parentId==item.id" :key="i+index.toString()" :label="item.name+'/'+it.name">
                      <template v-for="(em,i2) in countryList">
                        <el-option v-if="em.parentId==queryForm.region" :key="i2+i.toString()+index" :label="em.name" :value="em.id"></el-option>
                      </template>
                    </el-option-group>
                  </template>
                </template>
              </template>
            </template>
            <template v-else>
              <template v-for="(item,index) in countryList">
                <template v-if="item.parentId==0">
                  <template v-for="(it,i) in countryList">
                    <el-option-group v-if="it.parentId==item.id" :key="i+index.toString()" :label="item.name+'/'+it.name">
                      <template v-for="(em,i2) in countryList">
                        <el-option v-if="em.parentId==it.id" :key="i2+i.toString()+index" :label="em.name" :value="em.id"></el-option>
                      </template>
                    </el-option-group>
                  </template>
                </template>
              </template>
            </template>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('status')">
          <el-select v-model="queryForm.status" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('normal')" value="0"></el-option>
            <el-option :label="$t('lock')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isloading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column v-if="loginInfo.orgType==1" fixed="left" :label="$t('affiliationOrg')" width="200">
          <template slot-scope="scope">
            {{scope.row.orgName}}({{scope.row.orgInfoCode}})
          </template>
        </el-table-column>
        <el-table-column prop="name" fixed="left" :label="$t('customerName')" width="160">
          <template slot-scope="scope">{{scope.row.name+' '+ scope.row.surname}}</template>
        </el-table-column>
        <el-table-column prop="phoneNo" fixed="left" :label="$t('linkPhone')" min-width="160"></el-table-column>
        <el-table-column prop="customerCode" :label="$t('customerCode')" width="160"></el-table-column>
        <el-table-column prop="loginName" :label="$t('loginAccount')" width="160"></el-table-column>
        <!-- <el-table-column prop="type" :label="$t('accountType')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.type==5" class="text-primary">{{$t('individualAccount')}}</span>
            <span v-if="scope.row.type==6" class="text-primary">{{$t('companyAccount')}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="status" :label="$t('status')" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-success">{{$t('normal')}}</span>
            <span v-if="scope.row.status==1" class="text-danger">{{$t('lock')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="idCard" :label="$t('idNumber')" min-width="200"></el-table-column>
        <el-table-column prop="idImg" :label="$t('idCard')" min-width="200">
          <template slot-scope="scope">
            <el-image style="max-height:23px;" :src="scope.row.idImgs?scope.row.idImgs[0]:''" :preview-src-list="scope.row.idImgs">
              <div slot="error" class="image-slot">
                <img :src="imgError" style="max-height:23px;vertical-align: text-top;">
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="gender" :label="$t('sex')" min-width="160">
          <template slot-scope="scope">{{$t('sex'+scope.row.gender)}}</template>
        </el-table-column>
        <el-table-column prop="marryStatus" :label="$t('maritalStatus')" min-width="160">
          <template slot-scope="scope">{{$t('maritalStatus'+scope.row.marryStatus)}}</template>
        </el-table-column>
        <el-table-column prop="phoneNoBak" :label="$t('alternatePhone')" min-width="240">
          <template slot-scope="scope">
            <span>{{scope.row.phoneNoBak1||''}}</span>
            <span style="margin:0 10px"></span>
            <span>{{scope.row.phoneNoBak2||''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" :label="$t('area')" min-width="300">
          <template slot-scope="scope">
            {{getArea([scope.row.country,scope.row.region,scope.row.city])}}
          </template>
        </el-table-column>
        <el-table-column prop="address" :label="$t('address')" min-width="300"></el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" min-width="300" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="220">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="140">\
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==0" @click="onLock(scope.row,1)" size="small" type="primary">{{$t('lock')}}</el-button>
            <el-button v-if="scope.row.status==1" @click="onLock(scope.row,0)" size="small" type="warning">{{$t('unlock')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <!-- 锁定/解锁账户弹框 -->
    <el-drawer :title="$t(lockAccount.form.status==1?'lockAccount':'unlockAccount')" :visible.sync="lockAccount.drawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="lockAccount.form" :rules="rules" ref="lockAccount">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('customerName')">
              <el-input v-model="lockAccount.row.userName" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('customerCode')">
              <el-input v-model="lockAccount.row.customerCode" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('loginAccount')">
              <el-input v-model="lockAccount.row.loginName" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('linkPhone')">
              <el-input v-model="lockAccount.row.phoneNo" size="small" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-form-item :label="$t('reason')" prop="casue">
          <el-select v-model="lockAccount.form.casue" filterable>
            <el-option :label="'原因1'" :value="'原因1'"></el-option>
            <el-option :label="'原因2'" :value="'原因2'"></el-option>
            <el-option :label="'原因3'" :value="'原因3'"></el-option>
            <el-option :label="'原因4'" :value="'原因4'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('remark')" prop="remark">
          <el-input type="textarea" :row="2" v-model="lockAccount.form.remark"></el-input>
        </el-form-item> -->
      </el-form>
      <div class="bottom-button">
        <el-button size="medium" type="primary" :loading="isloading" @click="onSubmitLock('lockAccount')">{{$t(lockAccount.form.status==1?'lockAccount':'unlockAccount')}}</el-button>
      </div>
    </el-drawer>
  </el-card>
</template>

<script>
import customer from '@/api/customer'
import org from '@/api/org'
export default {
  name: "partnerAccount",
  data () {
    return {
      queryForm: { type: "6" },
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,

      isloading: false,

      orgList: [],
      lockAccount: {
        drawer: false,
        form: {},
        row: {}
      },
    }
  },
  computed: {
    countryList () { return this.$store.state.globalParameter.countryList; },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; },
    imgError () { return this.$store.state.system.imgError; },
    rules () { return {} }
  },
  watch: {
    /**选中省份后清空城市 */
    "queryForm.region" () { this.$set(this.queryForm, 'city', '') },
  },
  methods: {
    /**获取客户列表 */
    getList () {
      let _this = this;
      _this.isloading = true;
      customer.getPage({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.isloading = false;
          if (res.code != 200) {
            _this.tableData = {};
            return;
          }
          for (let i = 0; i < res.data.records.length; i++) {
            let l = res.data.records[i];
            l.idImgs = l.idImg ? l.idImg.split(',') : l.idImg;
          }
          _this.tableData = res.data;
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    getArea (arr) {
      let text = '';
      for (let i = 0; i < arr.length; i++) {
        let g = this.countryList.find(function (t) { return t.id == arr[i] })
        text += (g ? g.name : '') + ' / ';
      }
      return text.substring(0, text.length - 3);
    },
    getOrgList () {
      let _this = this;
      org.getOrgInfoPage({
        param: { current: 1, size: 10000, },
        success: res => {
          if (res.data)
            _this.orgList = res.data.records;
        }
      })
    },

    onLock (row, status) {
      console.log(row)
      row.userName = row.name + ' ' + row.surname;
      this.lockAccount.row = row;
      this.lockAccount.form = { id: row.id, status: status };
      this.lockAccount.drawer = true;
    },
    onSubmitLock (refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        customer.updateCustomerById({
          param: this.lockAccount.form,
          success: res => {
            if (res.code == 200) {
              this.getList();
              this.lockAccount.drawer = false;
              this.$message.success(this.$t('operateSuccessfully'));
            }
            else
              this.$message.warning(this.$t(res.msg));
          }
        })
      })
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();

    if (this.loginInfo.orgType == 1) {
      this.getOrgList();
    }
  }
}
</script>

<style>
</style>